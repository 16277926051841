import { render, staticRenderFns } from "./exerciseQBConfig.vue?vue&type=template&id=1ff43f30&scoped=true"
import script from "./exerciseQBConfig.vue?vue&type=script&lang=js"
export * from "./exerciseQBConfig.vue?vue&type=script&lang=js"
import style0 from "./exerciseQBConfig.vue?vue&type=style&index=0&id=1ff43f30&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff43f30",
  null
  
)

export default component.exports